import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Boat, ServiceBoat } from '../models';
import { BoatTableDTO } from '../models/dtos/boatTableDTO';
import { BoatFilter } from '../models/dtos/boat-filter';

import moment from 'moment';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BoatFilterListDTO } from '../models/dtos/boatFilterListDTO';
import { SelectLabelValue } from '../models/select-label-value';
import { BoatFilterListWithCustomerDTO } from '../models/dtos/boat-filter-list-with-customerDTO';
import { VacancyBoatDTO } from '../models/dtos/vacancy-boat-dto';
import { SelectorBoatDTO } from '../models/dtos/selector-boat-DTO';
import { BoatDTO } from '../models/dtos/boatDTO';
import { BoatReport } from '../models/boat-report.model';
import { EngineMark } from '../models/engine-mark';
import { ServiceBoatToWinServicesDTO } from '../models/dtos/serviceBoatToWinServicesDTO';

@Injectable()
export class BoatService {
  apiPath: string = environment.apiHost + '/secure';

  constructor(private http: HttpClient) {}

  getAll(campos?: string): Observable<Boat[]> {
    if (campos === undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .get(this.apiPath + '/boats/', { headers: headers })
      .pipe(map((response: any) => response.data.boats as Boat[]));
  }

  getAllForSelector(): Observable<SelectorBoatDTO[]> {
    let campos =
      'active,id,name,maintenance,commercialLength,boatCustomers{type,customer{active,id,name,phone,dueDate,issRetidoFonte,billingCompany}}';
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .get(this.apiPath + '/boats/selector/', { headers: headers })
      .pipe(map((response: any) => response.data.boats as SelectorBoatDTO[]));
  }

  getBoatFilterList(): Observable<BoatFilterListDTO[]> {
    return this.http
      .get(this.apiPath + '/boats/filter-list/')
      .pipe(map((response: any) => response.data.boats as BoatFilterListDTO[]));
  }

  getBoatFilterListWithCustomer(): Observable<BoatFilterListWithCustomerDTO[]> {
    return this.http
      .get(this.apiPath + '/boats/filter-list-with-customer/')
      .pipe(
        map(
          (response: any) =>
            response.data.boats as BoatFilterListWithCustomerDTO[]
        )
      );
  }

  getBoatsWithPeriodicalServicePriceFreeInUse(): Observable<
    BoatFilterListDTO[]
  > {
    return this.http
      .get(this.apiPath + '/boats/periodical-services-in-use/price-free')
      .pipe(
        map(
          (response: any) =>
            response.data
              .boatsWithPeriodicalServicesInUse as BoatFilterListDTO[]
        )
      );
  }

  findByMarinaIdWithPagination(boatFilter: BoatFilter): Observable<any[]> {
    const params = this.setPagination(boatFilter);
    return this.http
      .get(this.apiPath + '/boats/pagination', { params })
      .pipe(map((response: any) => response as any));
  }

  findByMarinaIdWithPaginationBoat(boatFilter: BoatFilter): Observable<Boat[]> {
    const params = this.setPagination(boatFilter);
    return this.http
      .get(this.apiPath + '/boats/pagination/e', { params })
      .pipe(map((response: any) => response.content as Boat[]));
  }

  private setPagination(boatFilter: BoatFilter): HttpParams {
    let params = new HttpParams();

    if (!boatFilter.sort || !boatFilter.order) {
      boatFilter.sort = 'name';
      boatFilter.order = 'ASC';
    }
    Object.keys(boatFilter).forEach((key) => {
      if (
        boatFilter[key] != null &&
        key !== 'vacanciesList' &&
        key !== 'movementGroup'
      ) {
        if (boatFilter[key] instanceof Date) {
          params = params.set(
            key,
            moment(boatFilter[key]).format('YYYY-MM-DD')
          );
        } else {
          params = params.set(key, boatFilter[key]);
        }
      }
    });
    if (boatFilter.vacanciesList.length > 0) {
      params = params.set('vacanciesId', boatFilter.vacanciesList.toString());
    }
    if (boatFilter.movementGroup.length > 0) {
      params = params.set(
        'movementGroupId',
        boatFilter.movementGroup.toString()
      );
    }
    params = params.set(
      'sort',
      boatFilter.sort.concat(',').concat(boatFilter.order)
    );
    params = params.set('page', boatFilter.page.toString());
    params = params.set('size', boatFilter.size.toString());
    return params;
  }

  getAllByActiveForSelect(): Observable<SelectLabelValue[]> {
    return this.http
      .get(this.apiPath + '/boats/select')
      .pipe(map((response: any) => response.data.boats as SelectLabelValue[]));
  }

  getAllActive(campos?: string): Observable<Boat[]> {
    if (campos === undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .get(this.apiPath + '/boats-active/', { headers })
      .pipe(map((response: any) => response.data.boats as Boat[]));
  }

  getById(id: number): Observable<Boat> {
    return this.http
      .get(this.apiPath + '/boat/' + id)
      .pipe(map((response: any) => response.data.boat));
  }

  getServicesBoatHistory(boatId: number): Observable<ServiceBoat[]> {
    return this.http
      .get(this.apiPath + '/boats/service-boat-history/' + boatId)
      .pipe(map((response: any) => response.data.servicesBoatHistory));
  }

  getNoVacancy(): Observable<Boat[]> {
    return this.http
      .get(this.apiPath + '/boat/no-vacancy/')
      .pipe(map((response: any) => response.data.boats as Boat[]));
  }

  getActiveNoVacancy(): Observable<BoatDTO[]> {
    return this.http
      .get(this.apiPath + '/boat/active/no-vacancy/')
      .pipe(map((response: any) => response.data.boats as BoatDTO[]));
  }

  getByTieRegister(register: string): Observable<Boat[]> {
    return this.http
      .get(this.apiPath + '/boat/tie-register/' + register)
      .pipe(map((response: any) => response.data.boat as Boat[]));
  }

  findByTieRegisterExists(
    marinaId: number,
    boatId: number,
    register: string
  ): Observable<boolean> {
    return this.http
      .get(
        `${this.apiPath}/boat/tie-register-exists/${marinaId}/${boatId}/${register}`
      )
      .pipe(map((response: any) => response.data.boat as boolean));
  }

  getByDpemRegister(register: string): Observable<Boat[]> {
    return this.http
      .get(this.apiPath + '/boat/dpem-register/' + register)
      .pipe(map((response: any) => response.data.boat as Boat[]));
  }

  findByDpemRegisterExists(
    marinaId: number,
    boatId: number,
    register: string
  ): Observable<boolean> {
    return this.http
      .get(
        `${this.apiPath}/boat/dpem-register-exists/${marinaId}/${boatId}/${register}`
      )
      .pipe(map((response: any) => response.data.boat as boolean));
  }

  create(boat: Boat): Observable<any> {
    return this.http
      .post(this.apiPath + '/boat/', boat)
      .pipe(map((response: any) => response));
  }

  update(boat: Boat): Observable<Boat> {
    return this.http
      .put(this.apiPath + '/boat/', boat)
      .pipe(map((response: any) => response));
  }

  updateStatus(boatId: number, active: boolean): Observable<Boat> {
    return this.http
      .put(this.apiPath + `/boat/update-status/${boatId}`, active)
      .pipe(map((response: any) => response));
  }

  updateReviewed(boatId: number, reviewed: boolean): Observable<Boat> {
    return this.http
      .put(this.apiPath + `/boat/update-reviewed/${boatId}`, reviewed)
      .pipe(map((response: any) => response));
  }

  delete(id: number): Observable<Boat> {
    return this.http
      .delete(this.apiPath + '/boat/' + id)
      .pipe(map((response: any) => response));
  }

  extractDetailServices(boatFilter: BoatFilter): Observable<any> {
    const params = this.setPagination(boatFilter);
    return this.http
      .get(this.apiPath + '/boat/extract-detail-services/', { params })
      .pipe(map((response: any) => response.data.extract));
  }

  updateBoatVancancy(idBoat: number, idVacancy: number): Observable<any> {
    return this.http
      .put(this.apiPath + `/boats/update-vacancy/${idBoat}/${idVacancy}`, {})
      .pipe(map((response: any) => response));
  }

  getQRCodes(boatIds): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http
      .get(this.apiPath + '/boats/qrcode/' + boatIds, {
        headers,
        responseType: 'blob',
      })
      .pipe(map((response: any) => response));
  }

  getQRCode(boatId): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'image/png');
    return this.http
      .get(this.apiPath + '/boat/qrcode/' + boatId, { responseType: 'blob' })
      .pipe(map((response: any) => response));
  }

  getTable(): Observable<BoatTableDTO[]> {
    return this.http
      .get(this.apiPath + '/boats/table')
      .pipe(map((response: any) => response.data.boats as BoatTableDTO[]));
  }

  uploadDocument(files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http
      .post(this.apiPath + '/boats/documentation-file/attachment', formData, {
        headers,
      })
      .pipe(map((response: any) => response.data.attachment));
  }

  uploadDocumentDpem(files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http
      .post(this.apiPath + '/boats/documentation-dpem-file/attachment', formData, {
        headers,
      })
      .pipe(map((response: any) => response.data.attachment));
  }

  getExpiredPeriodicalService(): Observable<number> {
    return this.http
      .get(this.apiPath + '/boats/number-expired-periodical-service')
      .pipe(map((response: any) => response.data.count as number));
  }

  getToWinExpirePeriodicalService(): Observable<number> {
    return this.http
      .get(this.apiPath + '/boats/number-periodical-service-to-win-expire')
      .pipe(map((response: any) => response.data.count as number));
  }

  getExpiringServices(): Observable<ServiceBoatToWinServicesDTO[]> {
    return this.http
      .get(this.apiPath + '/boats/number-due-periodical-service')
      .pipe(
        map((response: any) => {
          return response.data.services as ServiceBoatToWinServicesDTO[];
        })
      );
  }


  extractBoatHistory(filter: any): Observable<any> {
    return this.http
      .post(this.apiPath + '/boat/history/pdf', filter)
      .pipe(map((response: any) => response.data.extract));
  }

  getNumberNeverWashOrEngineTurn(): Observable<number> {
    return this.http
      .get(this.apiPath + '/boats/count-never-wash-or-engine-turn')
      .pipe(
        map(
          (response: any) =>
            response.data.numberOfNeverWashNeverEngineTurn as number
        )
      );
  }

  findBoatIdByServiceBoatId(serviceBoatId: number): Observable<number> {
    return this.http
      .get(`${this.apiPath}/boat/id/service-boat/${serviceBoatId}`)
      .pipe(map((response: any) => response.data.boatId as number));
  }

  getVacancyDTO(boatId: number): Observable<VacancyBoatDTO> {
    return this.http
      .get(`${this.apiPath}/boats/${boatId}/vacancy`)
      .pipe(map((response: any) => response.data.vacancy as VacancyBoatDTO));
  }

  getInactiveBoats(): Observable<any> {
    return this.http
      .get(this.apiPath + '/boat/active-false')
      .pipe(map((response: any) => response.data.activeFalse));
  }

  getBoatsByIdCustomer(customerId: number): Observable<any> {
    return this.http
      .get(`${this.apiPath}/boat/blocked-boats/${customerId}`)
      .pipe(map((response: any) => response.data.boats));
  }

  blockBoat(
    customerId: number,
    blocked: boolean,
    boatId: number
  ): Observable<any> {
    return this.http
      .put(
        `${this.apiPath}/boat/block-boat/${customerId}/${boatId}/${blocked}`,
        {}
      )
      .pipe();
  }

  getServicesWithoutContracts(boatId: number): Observable<any> {
    const getUrl = '/service-boat/contract/null/';
    return this.http
      .get(this.apiPath + getUrl + boatId)
      .pipe(map((response: any) => response.data.findServicesWithoutContracts));
  }

  getServicesWithContracts(boatId: null): Observable<any> {
    const getUrl = '/service-boat/contract/not_null/';
    return this.http
      .get(this.apiPath + getUrl + boatId)
      .pipe(map((response: any) => response.data.findServicesWhitContracts));
  }

  getEngineMarks(): Observable<EngineMark[]> {
    return this.http
      .get(this.apiPath + '/boat/find-all/engine-marks/')
      .pipe(map((response: any) => response.data.engineMarks as EngineMark[]));
  }

  //------------------Nova Api------------------

  getBoatByCustomerAndActive(
    customerId: number,
    active: boolean
  ): Observable<any> {
    return this.http
      .get(
        environment.apiHost +
          '/api-core/secure/boat/querys/get-simpleboat-customer-active/' +
          customerId +
          '/' +
          active
      )
      .pipe(map((response: any) => response));
  }

  getBoatReport(year: number, body: any): Observable<BoatReport> {
    return this.http
      .post(
        environment.apiHost +
          '/api-core/secure/boat/querys/boat-report/' +
          year,
        body
      )
      .pipe(map((response: BoatReport) => response));
  }
}
